import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/componants/ProfilePageComponants/FeatureImage.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/componants/HomePageComponants/Card.jsx\",\"import\":\"Bilbo_Swash_Caps\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"weight\":[\"400\"],\"adjustFontFallback\":{\"sizeAdjust\":\"96%\"},\"preload\":true}],\"variableName\":\"bilboSwashCaps\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/1.avif");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/2.avif");
